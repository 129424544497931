import React from 'react';
import { getFileType } from '../../utils/fileUtils';
import './PreviewData.css';

const PreviewData = ({ documentPreview, documentName }) => {
  const fileType = getFileType(documentName);

  return (
    <div className="contract-data-card">
      <div className="contract-data-preview">
        {documentPreview ? (
          fileType === 'image' ? (
            <img src={documentPreview} alt="Document Preview" className="document-preview" />
          ) : fileType === 'audio' ? (
            <div className="audio-preview">
              <div className="file-name">{documentName}</div>
              <audio controls className="document-preview">
                <source src={documentPreview} type={`audio/${documentName.split('.').pop()}`} />
                Your browser does not support the audio element.
              </audio>
            </div>
          ) : fileType === 'video' ? (
            <video controls className="document-preview">
              <source src={documentPreview} type={`video/${documentName.split('.').pop()}`} />
              Your browser does not support the video element.
            </video>
          ) : fileType === 'document' ? (
            <iframe
              src={documentPreview}
              frameBorder="0"
              className="document-preview document-preview-large"
              title="Document Preview"
            ></iframe>
          ) : (
            <div className="file-info">
              <p>{documentName}</p>
              <p>No preview available for this file type.</p>
            </div>
          )
        ) : (
          <div className="placeholder">No File Uploaded</div>
        )}
      </div>
    </div>
  );
};

export default PreviewData;
