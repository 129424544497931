import React, { useState, useEffect, useCallback } from 'react';
import { ethers } from 'ethers';
import { useUser } from '../UserContext';
import { calculateFileHash } from '../../utils/fileUtils';
import pdfIcon from './filetypes.png';
import Button from '../common/Button';
import './FileUploadSection.css';

const MAX_TAG_LENGTH = 300;
const MAX_NAME_LENGTH = 100;

const FileUploadSection = ({ onFileSelect, addDocument, setDocumentPreview, setDocumentName, contract }) => {
  const { userAddress, connectMetaMask } = useUser();
  const [isDragOver, setIsDragOver] = useState(false);
  const [fileHash, setFileHash] = useState('');
  const [loading, setLoading] = useState(false);
  const [tag, setTag] = useState('');
  const [name, setName] = useState('');
  const [feedback, setFeedback] = useState({ type: '', message: '' });
  const [transactionStatus, setTransactionStatus] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [documentExists, setDocumentExists] = useState(false);
  const [documentDetails, setDocumentDetails] = useState(null);
  const [fileChecked, setFileChecked] = useState(false);
  const [searchHash, setSearchHash] = useState('');
  const [registrationFee, setRegistrationFee] = useState(0);

  useEffect(() => {
    const fetchRegistrationFee = async () => {
      if (contract) {
        try {
          const fee = await contract.getRegistrationFeeETH();
          setRegistrationFee(fee);
        } catch (error) {
          setFeedback({ type: 'error', message: `Error fetching registration fee` });
        }
      }
    };

    fetchRegistrationFee();
  }, [contract]);
  
  const resetForm = () => {
    setFileHash('');
    setLoading(false);
    setTag('');
    setName('');
    setFeedback({ type: '', message: '' });
    setTransactionStatus('');
    setDocumentPreview(null);
    setDocumentExists(false);
    setDocumentDetails(null);
    setFileChecked(false);
  };

  const handleFileChange = useCallback(async (event) => {
    const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    if (files.length > 0) {
      const file = files[0];
      setLoading(true);
      onFileSelect(file);
      try {
        const hash = await calculateFileHash(file);
        setFileHash(hash);
        setDocumentName(file.name);
        const reader = new FileReader();
        reader.onloadend = () => {
          setDocumentPreview(reader.result);
        };
        reader.readAsDataURL(file);

        // Check if the document already exists on the blockchain
        const existingDocument = await contract.getDocumentDetails(`0x${hash}`);
        if (existingDocument._documentHash !== ethers.constants.HashZero) {
          setDocumentDetails(existingDocument);
          setDocumentExists(true);
          setFeedback({ type: 'success', message: 'Document already registered on the blockchain' });
        } else {
          setDocumentExists(false);
          setFeedback({ type: 'error', message: 'Invalid or non-existing document on Blockchain' });
        }
        setFileChecked(true);
      } catch (error) {
        if (error.reason && error.reason.includes('Document does not exist')) {
          setFeedback({ type: 'error', message: 'Invalid or non-existing document on Blockchain' });
        } else {
          setFeedback({ type: 'error', message: `Error when reading file` });
        }
        setFileChecked(true);
      } finally {
        setLoading(false);
      }
    }
  }, [onFileSelect, setDocumentPreview, setDocumentName, contract]);

  const handleTagChange = (e) => {
    setTag(e.target.value);
    if (e.target.value.length > MAX_TAG_LENGTH) {
      setFeedback({ type: 'error', message: `Tag cannot exceed ${MAX_TAG_LENGTH} characters` });
    } else {
      setFeedback({ type: '', message: '' });
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    if (e.target.value.length > MAX_NAME_LENGTH) {
      setFeedback({ type: 'error', message: `Name cannot exceed ${MAX_NAME_LENGTH} characters` });
    } else {
      setFeedback({ type: '', message: '' });
    }
  };

  const handleUploadClick = async () => {
    if (!userAddress) {
      await connectMetaMask();
    }
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'application/pdf, image/*, audio/*, video/*';
    fileInput.onchange = handleFileChange;
    fileInput.click();
  };

  const handleBackClick = () => {
    resetForm();
  };

  const handleProceedClick = () => {
    setFileChecked(false);
    setFeedback({ type: '', message: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userAddress) {
      await connectMetaMask();
    }
    if (tag.length > MAX_TAG_LENGTH || name.length > MAX_NAME_LENGTH) {
      setFeedback({ type: 'error', message: `Fields exceed maximum length` });
      return;
    }
    try {
      setTransactionStatus('Transaction in progress...');
      setFeedback({ type: 'info', message: 'Transaction in progress...' });

      if (contract) {
        const transaction = await contract.registerDocument(
          `0x${fileHash}`,
          name,
          tag,
          {
            gasLimit: 600000,
            value: registrationFee,
          }
        );
        console.log('Transaction sent:', transaction.hash);
        const receipt = await transaction.wait();
        if (receipt.status === 1) {
          // Fetch the newly created document details
          const newDocument = await contract.getDocumentDetails(`0x${fileHash}`);
          setFeedback({ type: 'success', message: 'Document uploaded successfully' });
          addDocument({
            documentHash: fileHash,
            name: newDocument._name,
            tag: newDocument._tag,
            createdOn: newDocument._timestampCreation.toNumber() * 1000,
            owner: newDocument._uploader,
          });
          setNotificationMessage(`Congratulations! You have made your ${name || tag} timeless!`);
          setShowNotification(true);
          setDocumentPreview(null);
        } else {
          setFeedback({ type: 'error', message: 'Transaction failed' });
        }
        resetForm();
      } else {
        setFeedback({ type: 'error', message: "Ethereum object doesn't exist!" });
        setTransactionStatus('');
      }
    } catch (error) {
      console.error('Error uploading document:', error);
      setFeedback({ type: 'error', message: `Error when uploading document` });
      setTransactionStatus('');
    }
  };

  const handleSearchHashChange = (e) => {
    setSearchHash(e.target.value);
  };

  const normalizeHash = (hash) => {
    return hash.startsWith('0x') ? hash : `0x${hash}`;
  };

  const handleSearchHashSubmit = async () => {
    if (searchHash.trim() === '') {
      setFeedback({ type: 'error', message: 'Please enter a hash to search' });
      return;
    }
    setLoading(true);
    const normalizedHash = normalizeHash(searchHash.trim());
    try {
      const existingDocument = await contract.getDocumentDetails(normalizedHash);
      if (existingDocument._documentHash !== ethers.constants.HashZero) {
        setDocumentDetails(existingDocument);
        setDocumentExists(true);
        setFeedback({ type: 'success', message: 'Document found on the blockchain' });
      } else {
        setDocumentExists(false);
        setFeedback({ type: 'error', message: 'Invalid or non-existing document on Blockchain' });
      }
      setFileChecked(true);
    } catch (error) {
      if (error.reason && error.reason.includes('Document does not exist')) {
        setFeedback({ type: 'error', message: 'Invalid or non-existing document on Blockchain' });
      } else {
        setFeedback({ type: 'error', message: `Error when searching for document` });
      }
      setFileChecked(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showNotification) {
      const timer = setTimeout(() => {
        setShowNotification(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showNotification]);

  const handleDragOver = (event) => {
    event.preventDefault();
    if (!isDragOver) setIsDragOver(true);
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragOver(false);
    handleFileChange(event);
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (fileChecked) {
    if (documentExists) {
      return (
        <div className="document-details">
          <h3>Document is Valid</h3>
          <p><strong>Hash:</strong> {documentDetails._documentHash.replace(/^0x/, '')}</p>
          <p><strong>Name:</strong> {documentDetails._name}</p>
          <p><strong>Tag:</strong> {documentDetails._tag}</p>
          <p><strong>Uploaded by:</strong> {documentDetails._uploader}</p>
          <p><strong>Creation Date:</strong> {new Date(documentDetails._timestampCreation * 1000).toLocaleDateString()}</p>
          <div className="button-group">
            <button type="button" className="back-button" onClick={handleBackClick}>Back</button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="document-details">
          <h3>INVALID DOCUMENT OR NOT ON BLOCKCHAIN</h3>
          <p>Do you want to proceed to register the file on the Blockchain?</p>
          <div className="button-group">
            <button type="button" className="back-button" onClick={handleBackClick}>Back</button>
            <button type="button" className="proceed-button" onClick={handleProceedClick}>Proceed</button>
          </div>
        </div>
      );
    }
  }

  if (fileHash) {
    return (
      <form onSubmit={handleSubmit} className="options-form">
        <div>Your Hash</div>
        <input type="text" value={fileHash.replace(/^0x/, '')} readOnly id="fileHash" name="fileHash" />
        <label htmlFor="name">
          Name:
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={handleNameChange}
            className="name-input"
          />
          <small className="name-description">A name helps you identify your document.</small>
        </label>
        <label htmlFor="tag">
          Tag:
          <input
            type="text"
            id="tag"
            name="tag"
            value={tag}
            onChange={handleTagChange}
            className="tag-input"
          />
          <small className="tag-description">A tag helps you to categorize your document for easier search.</small>
        </label>
        <div className="button-group">
          <button type="submit" className="submit-button" disabled={tag.length > MAX_TAG_LENGTH || name.length > MAX_NAME_LENGTH}>Make Timeless</button>
          <button type="button" className="back-button" onClick={handleBackClick}>Back</button>
        </div>
        {feedback.message && <div className={`feedback ${feedback.type}`}>{feedback.message}</div>}
      </form>
    );
  }

  return (
    <div className="file-upload-section">
      <div
        className={`file-drop-area ${isDragOver ? 'drag-over' : ''}`}
        onDragOver={handleDragOver}
        onDragEnter={() => setIsDragOver(true)}
        onDragLeave={() => setIsDragOver(false)}
        onDrop={(e) => {
          handleDrop(e);
          setIsDragOver(false);
        }}
      >
        <img src={pdfIcon} alt="PDF" className="pdf-icon" />
        <p>Drag and drop your file here</p>
        <Button onClick={handleUploadClick} className="button">
          Check File
        </Button>
      </div>
      <div className="hash-search">
        <input 
          type="text" 
          value={searchHash} 
          onChange={handleSearchHashChange} 
          placeholder="Enter document hash to search" 
          className="hash-input"
        />
        <Button onClick={handleSearchHashSubmit} className="button">
          Search Hash
        </Button>
      </div>
      {feedback.message && feedback.type !== 'error' && (
        <div className={`feedback ${feedback.type}`}>
          {feedback.message}
        </div>
      )}
      {transactionStatus && (
        <div className="transaction-status">
          {transactionStatus}
        </div>
      )}
      {showNotification && (
        <div className="notification">
          {notificationMessage}
        </div>
      )}
    </div>
  );
};

export default FileUploadSection;
