import React from 'react';
import './ServiceFees.css';

const ServiceFees = () => {
  return (
    <div className="service-fees">
      <h1>Service Fees</h1>
      <section>
        <h2>Introduction</h2>
        <p>
          At Ethernal Doc, we strive to provide transparent and fair pricing for our services. Below is a breakdown of the costs associated with using our platform.
        </p>
      </section>

      <section>
        <h2>Fee Structure</h2>
        <ul>
          <li><strong>Document Registration Fee</strong>: Each document registered on the blockchain incurs a fee. This fee covers the cost of the transaction on the Ethereum network.</li>
          <li><strong>Document Verification Fee</strong>: Verifying the existence of a document on the blockchain is free of charge.</li>
          <li><strong>Network Gas Fees</strong>: All transactions require gas fees, which are determined by the Ethereum or Layer 2 network at the time of the transaction. These fees are not controlled by Ethernal Doc and may vary.</li>
        </ul>
      </section>

      <section>
        <h2>Current Fees</h2>
        <table className="fee-table">
          <thead>
            <tr>
              <th>Service</th>
              <th>Fee</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Document Registration</td>
              <td>0.003 ETH</td>
            </tr>
            <tr>
              <td>Document Verification</td>
              <td>Free</td>
            </tr>
            <tr>
              <td>Network Gas Fees</td>
              <td>Variable (determined by Ethereum network)</td>
            </tr>
          </tbody>
        </table>
      </section>

      <section>
        <h2>Additional Information</h2>
        <p>
          The fees listed above are subject to change based on network conditions and service improvements. We are committed to keeping our users informed about any changes in our fee structure. Please refer to this page for the most up-to-date information.
        </p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about our service fees, please contact us at: <a href="mailto:support@ethernaldoc.com">support@ethernaldoc.com</a>.
        </p>
      </section>
    </div>
  );
};

export default ServiceFees;
