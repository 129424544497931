import CryptoJS from 'crypto-js';

export const calculateFileHash = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      const fileData = e.target.result;
      const hash = CryptoJS.SHA256(CryptoJS.enc.Latin1.parse(fileData)).toString();
      resolve(hash);
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.readAsBinaryString(file);
  });
};

export const isImage = (file) => /\.(jpg|jpeg|png|gif|bmp|tiff|webp)$/i.test(file);
export const isAudio = (file) => /\.(mp3|wav|ogg|aac|flac|m4a|wma)$/i.test(file);
export const isVideo = (file) => /\.(mp4|webm|ogg|avi|mkv|mov|wmv|flv)$/i.test(file);
export const isDocument = (file) => /\.(pdf|doc|docx|xls|xlsx|ppt|pptx|txt)$/i.test(file);

export const getFileType = (file) => {
  if (isImage(file)) return 'image';
  if (isAudio(file)) return 'audio';
  if (isVideo(file)) return 'video';
  if (isDocument(file)) return 'document';
  return 'other';
};
