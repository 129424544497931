import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <section>
        <h2>Introduction</h2>
        <p>
          Welcome to Ethernal Doc. Your privacy is important to us. This Privacy Policy explains how we handle your data when you use our decentralized application (dApp).
        </p>
      </section>
      
      <section>
        <h2>Data Collection</h2>
        <p>
          Ethernal Doc is a decentralized application built on the Ethereum blockchain. We do not collect or store any personal data from our users. Your interactions with the blockchain are transparent and secure, but they are not recorded or stored by us.
        </p>
      </section>
      
      <section>
        <h2>How We Use Your Information</h2>
        <p>
          Since we do not collect any personal data, we do not use any personal information. All document uploads, verifications, and registrations are handled on the blockchain, ensuring complete privacy and security.
        </p>
      </section>

      <section>
        <h2>Blockchain Transactions</h2>
        <p>
          All transactions performed on Ethernal Doc are recorded on the Ethereum blockchain. These transactions are public and can be viewed by anyone. However, these records do not contain personal data beyond your public wallet address.
        </p>
      </section>

      <section>
        <h2>MetaMask Integration</h2>
        <p>
          Ethernal Doc integrates with MetaMask to facilitate interactions with the Ethereum blockchain. We do not have access to your MetaMask wallet or any of its data. Your wallet interactions are managed by MetaMask and are subject to MetaMask’s privacy policies.
        </p>
      </section>

      <section>
        <h2>Data Security</h2>
        <p>
          The security of your data is ensured by the Ethereum blockchain. Blockchain technology provides a high level of security and immutability for your transactions and document registrations.
        </p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us at: <a href="mailto:support@ethernaldoc.com">support@ethernaldoc.com</a>.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
