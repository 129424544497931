import React, { useState } from 'react';
import './Documentation.css';

const Documentation = () => {
  const [language, setLanguage] = useState('en'); // Default language

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  return (
    <div className="documentation">
      <h1>Documentation</h1>
      <div className="language-selector">
        <label htmlFor="language">Choose language: </label>
        <select id="language" value={language} onChange={handleLanguageChange}>
          <option value="en">English</option>
          <option value="it">Italian</option>
          <option value="de">German</option>
          <option value="es">Spanish</option>
          <option value="fr">French</option>
        </select>
      </div>
      {language === 'en' && (
        <div className="content">
        <section>
          <h2>Introduction</h2>
          <p>
            Welcome to Ethernal Doc, the decentralized application (dApp) designed to provide a secure and transparent way to upload, verify, and register documents on the blockchain. In a world where data integrity is crucial, Ethernal Doc offers a reliable solution to ensure the authenticity of your digital documents.
          </p>
          <p>
            With Ethernal Doc, you can:
          </p>
          <ul>
            <li><strong>Upload Documents</strong>: Upload your files directly from the simple and intuitive user interface. We support a wide range of file formats, including PDFs, images, audio, and video.</li>
            <li><strong>Verify Documents</strong>: Check if your documents are already registered on the blockchain. This ensures that your documents have not been tampered with.</li>
            <li><strong>Register Documents</strong>: If the document is not yet on the blockchain, you can easily register it, ensuring that any future modifications can be tracked and verified.</li>
            <li><strong>Search Documents by Hash</strong>: Use the document hash to quickly search and verify the presence of the document on the blockchain.</li>
          </ul>
          <p>
            Ethernal Doc uses blockchain technology to create an immutable and transparent ledger of transactions. Each registered document is associated with a unique hash, acting as a digital fingerprint of the file. This hash is then saved on the blockchain, making it impossible to alter without detection.
          </p>
          <p>
            The application integrates seamlessly with MetaMask, one of the most popular solutions for identity and wallet management on Ethereum. Ensure that MetaMask is installed and properly configured to interact with Ethernal Doc.
          </p>
          <p>
            Start using Ethernal Doc now to ensure the security and integrity of your digital documents.
          </p>
        </section>

        <section>
          <h2>Requirements</h2>
          <ul>
            <li>A modern web browser (e.g., Chrome, Firefox)</li>
            <li>MetaMask installed as a browser extension</li>
            <li>Ether (ETH) to pay transaction fees on the Ethereum or related L2 network</li>
          </ul>
        </section>

        <section>
          <h2>Main Features</h2>
          <h3>Uploading Documents</h3>
          <p>
            1. Drag and drop a file into the upload area or click the "Upload file" button to select a file from your computer.
            2. Once the file is uploaded, the document's hash will be calculated, and a preview of the document will be displayed.
          </p>

          <h3>Verifying Documents</h3>
          <p>
            1. After uploading a file, the application checks if the document's hash already exists on the blockchain.
            2. If the document exists, the document details (e.g., hash, name, tag, creation date, etc.) will be displayed.
            3. If the document does not exist, an error message will be shown, and the user can choose to register the document on the blockchain.
          </p>

          <h3>Registering Documents on the Blockchain</h3>
          <p>
            1. Enter a name and tag (both optional) for the document.
            2. Click the "Make Timeless" button to initiate the transaction that registers the document on the blockchain.
            3. Confirm the transaction in MetaMask and wait for the transaction to complete.
            4. Once completed, a success message will be displayed.
          </p>

          <h3>Searching Documents by Hash</h3>
          <p>
            1. Enter the document hash in the search field located in the upload section.
            2. Click the "Search Hash" button to verify the document's existence on the blockchain.
            3. If the document exists, the document details will be displayed.
          </p>
        </section>

        <section>
          <h2>Common Issues</h2>
          <ul>
            <li><strong>Error "Document does not exist"</strong>: Ensure that the document's hash is correct and that the document has been previously registered on the blockchain.</li>
            <li><strong>Transaction failed</strong>: Check that you have sufficient funds in ETH to cover the transaction fees.</li>
            <li><strong>Connection issues with MetaMask</strong>: Ensure that MetaMask is installed and that your account is connected to the correct network (e.g., Ethereum Mainnet).</li>
          </ul>
        </section>

        <section>
          <h2>Contact</h2>
          <p>
            For further information or support, contact the development team at: <a href="mailto:support@ethernaldoc.com">support@ethernaldoc.com</a>.
          </p>
        </section>
      </div>
      )}
      {language === 'it' && (
        <div className="content">
          <section>
            <h2>Introduzione</h2>
            <p>
              Benvenuti in Ethernal Doc, l'applicazione decentralizzata (dApp) progettata per offrire un modo sicuro e trasparente per caricare, verificare e registrare documenti sulla blockchain. In un mondo dove l'integrità dei dati è cruciale, Ethernal Doc fornisce una soluzione affidabile per garantire l'autenticità dei tuoi documenti digitali.
            </p>
            <p>
              Con Ethernal Doc, puoi:
            </p>
            <ul>
              <li>**Caricare Documenti**: Carica i tuoi file direttamente dall'interfaccia utente semplice e intuitiva. Supportiamo una vasta gamma di formati di file, inclusi PDF, immagini, audio e video.</li>
              <li>**Verificare Documenti**: Controlla se i tuoi documenti sono già registrati sulla blockchain. Questo ti permette di assicurarti che i tuoi documenti non siano stati manomessi.</li>
              <li>**Registrare Documenti**: Se il documento non è ancora sulla blockchain, puoi facilmente registrarlo, garantendo che ogni modifica futura possa essere tracciata e verificata.</li>
              <li>**Ricercare Documenti tramite Hash**: Utilizza l'hash del documento per cercare rapidamente e verificare la presenza del documento sulla blockchain.</li>
            </ul>
            <p>
              Ethernal Doc utilizza la tecnologia blockchain per creare un registro immutabile e trasparente delle transazioni. Ogni documento registrato viene associato a un hash unico, che agisce come un'impronta digitale del file. Questo hash viene poi salvato sulla blockchain, rendendo impossibile la sua alterazione senza che sia rilevabile.
            </p>
            <p>
              L'applicazione si integra perfettamente con MetaMask, una delle soluzioni più popolari per la gestione di identità e portafogli su Ethereum. Assicurati di avere MetaMask installato e configurato correttamente per interagire con Ethernal Doc.
            </p>
            <p>
              Inizia subito a utilizzare Ethernal Doc per garantire la sicurezza e l'integrità dei tuoi documenti digitali.
            </p>
          </section>

          <section>
            <h2>Requisiti</h2>
            <ul>
              <li>Un browser web moderno (es. Chrome, Firefox)</li>
              <li>MetaMask installato come estensione del browser</li>
              <li>Ether (ETH) per pagare le commissioni di transazione sulla rete Ethereum o sulla rete L2 correlata.</li>
            </ul>
          </section>

          <section>
            <h2>Funzionalità Principali</h2>
            <h3>Caricamento di Documenti</h3>
            <p>
              1. Trascina e rilascia un file nell'area di caricamento o clicca sul pulsante "Upload file" per selezionare un file dal tuo computer.
              2. Una volta caricato il file, verrà calcolato l'hash del documento e verrà mostrata un'anteprima del documento.
            </p>

            <h3>Verifica di Documenti</h3>
            <p>
              1. Dopo aver caricato un file, l'applicazione verifica se l'hash del documento esiste già sulla blockchain.
              2. Se il documento esiste, verranno mostrati i dettagli del documento (es. hash, nome, tag, data di creazione, ecc.).
              3. Se il documento non esiste, verrà mostrato un messaggio di errore e l'utente potrà scegliere se registrare il documento sulla blockchain.
            </p>

            <h3>Registrazione di Documenti sulla Blockchain</h3>
            <p>
              1. Inserisci un nome e un tag (entrambi facoltativi) per il documento. 
              2. Clicca sul pulsante "Make Timeless" per avviare la transazione che registra il documento sulla blockchain.
              3. Conferma la transazione in MetaMask e attendi il completamento della transazione.
              4. Una volta completata, verrà mostrato un messaggio di successo.
            </p>

            <h3>Ricerca di Documenti tramite Hash</h3>
            <p>
              1. Inserisci l'hash del documento nel campo di ricerca presente nella sezione di upload.
              2. Clicca sul pulsante "Search Hash" per verificare l'esistenza del documento sulla blockchain.
              3. Se il documento esiste, verranno mostrati i dettagli del documento.
            </p>
          </section>

          <section>
            <h2>Risoluzione dei Problemi Comuni</h2>
            <ul>
              <li><strong>Errore "Document does not exist"</strong>: Assicurarsi che l'hash del documento sia corretto e che il documento sia stato precedentemente registrato sulla blockchain.</li>
              <li><strong>Transazione fallita</strong>: Controllare di avere sufficienti fondi in ETH per coprire le commissioni di transazione.</li>
              <li><strong>Problemi di connessione a MetaMask</strong>: Verificare che MetaMask sia installato e che l'account sia connesso alla rete corretta (es. Ethereum Mainnet).</li>
            </ul>
          </section>

          <section>
            <h2>Contatti</h2>
            <p>
              Per ulteriori informazioni o supporto, contattare il team di sviluppo all'indirizzo email: <a href="mailto:support@ethernaldoc.com">support@ethernaldoc.com</a>.
            </p>
          </section>
        </div>
      )}
      {language === 'de' && (
        <div className="content">
        <section>
          <h2>Einführung</h2>
          <p>
            Willkommen bei Ethernal Doc, der dezentralen Anwendung (dApp), die entwickelt wurde, um eine sichere und transparente Möglichkeit zum Hochladen, Überprüfen und Registrieren von Dokumenten auf der Blockchain zu bieten. In einer Welt, in der Datenintegrität von entscheidender Bedeutung ist, bietet Ethernal Doc eine zuverlässige Lösung zur Sicherstellung der Authentizität Ihrer digitalen Dokumente.
          </p>
          <p>
            Mit Ethernal Doc können Sie:
          </p>
          <ul>
            <li><strong>Dokumente hochladen</strong>: Laden Sie Ihre Dateien direkt über die einfache und intuitive Benutzeroberfläche hoch. Wir unterstützen eine Vielzahl von Dateiformaten, einschließlich PDFs, Bilder, Audio und Video.</li>
            <li><strong>Dokumente überprüfen</strong>: Überprüfen Sie, ob Ihre Dokumente bereits auf der Blockchain registriert sind. Dies stellt sicher, dass Ihre Dokumente nicht manipuliert wurden.</li>
            <li><strong>Dokumente registrieren</strong>: Wenn das Dokument noch nicht auf der Blockchain ist, können Sie es einfach registrieren, um sicherzustellen, dass zukünftige Änderungen verfolgt und überprüft werden können.</li>
            <li><strong>Dokumente nach Hash durchsuchen</strong>: Verwenden Sie den Dokumenten-Hash, um schnell zu suchen und das Vorhandensein des Dokuments auf der Blockchain zu überprüfen.</li>
          </ul>
          <p>
            Ethernal Doc nutzt die Blockchain-Technologie, um ein unveränderliches und transparentes Transaktionsbuch zu erstellen. Jedes registrierte Dokument wird mit einem eindeutigen Hash verknüpft, der als digitaler Fingerabdruck der Datei dient. Dieser Hash wird dann auf der Blockchain gespeichert, wodurch es unmöglich wird, ihn ohne Entdeckung zu ändern.
          </p>
          <p>
            Die Anwendung integriert sich nahtlos mit MetaMask, einer der beliebtesten Lösungen für Identitäts- und Wallet-Management auf Ethereum. Stellen Sie sicher, dass MetaMask installiert und ordnungsgemäß konfiguriert ist, um mit Ethernal Doc zu interagieren.
          </p>
          <p>
            Beginnen Sie jetzt mit der Nutzung von Ethernal Doc, um die Sicherheit und Integrität Ihrer digitalen Dokumente zu gewährleisten.
          </p>
        </section>
  
        <section>
          <h2>Anforderungen</h2>
          <ul>
            <li>Ein moderner Webbrowser (z.B. Chrome, Firefox)</li>
            <li>MetaMask als Browsererweiterung installiert</li>
            <li>Ether (ETH) zur Zahlung von Transaktionsgebühren im Ethereum- oder verwandten L2-Netzwerk</li>
          </ul>
        </section>
  
        <section>
          <h2>Hauptfunktionen</h2>
          <h3>Dokumente hochladen</h3>
          <p>
            1. Ziehen Sie eine Datei in den Upload-Bereich oder klicken Sie auf die Schaltfläche "Datei hochladen", um eine Datei von Ihrem Computer auszuwählen.
            2. Sobald die Datei hochgeladen ist, wird der Hash des Dokuments berechnet und eine Vorschau des Dokuments angezeigt.
          </p>
  
          <h3>Dokumente überprüfen</h3>
          <p>
            1. Nach dem Hochladen einer Datei überprüft die Anwendung, ob der Hash des Dokuments bereits auf der Blockchain existiert.
            2. Wenn das Dokument existiert, werden die Dokumentdetails (z.B. Hash, Name, Tag, Erstellungsdatum usw.) angezeigt.
            3. Wenn das Dokument nicht existiert, wird eine Fehlermeldung angezeigt und der Benutzer kann wählen, ob er das Dokument auf der Blockchain registrieren möchte.
          </p>
  
          <h3>Dokumente auf der Blockchain registrieren</h3>
          <p>
            1. Geben Sie einen Namen und einen Tag (beides optional) für das Dokument ein.
            2. Klicken Sie auf die Schaltfläche "Zeitlos machen", um die Transaktion zu starten, die das Dokument auf der Blockchain registriert.
            3. Bestätigen Sie die Transaktion in MetaMask und warten Sie auf den Abschluss der Transaktion.
            4. Nach Abschluss wird eine Erfolgsmeldung angezeigt.
          </p>
  
          <h3>Dokumente nach Hash durchsuchen</h3>
          <p>
            1. Geben Sie den Hash des Dokuments in das Suchfeld im Upload-Bereich ein.
            2. Klicken Sie auf die Schaltfläche "Hash suchen", um die Existenz des Dokuments auf der Blockchain zu überprüfen.
            3. Wenn das Dokument existiert, werden die Dokumentdetails angezeigt.
          </p>
        </section>
  
        <section>
          <h2>Häufige Probleme</h2>
          <ul>
            <li><strong>Fehler "Document does not exist"</strong>: Stellen Sie sicher, dass der Dokumenten-Hash korrekt ist und dass das Dokument zuvor auf der Blockchain registriert wurde.</li>
            <li><strong>Transaktion fehlgeschlagen</strong>: Überprüfen Sie, ob Sie genügend Mittel in ETH haben, um die Transaktionsgebühren zu decken.</li>
            <li><strong>Verbindungsprobleme mit MetaMask</strong>: Stellen Sie sicher, dass MetaMask installiert ist und Ihr Konto mit dem richtigen Netzwerk verbunden ist (z.B. Ethereum Mainnet).</li>
          </ul>
        </section>
  
        <section>
          <h2>Kontakt</h2>
          <p>
            Für weitere Informationen oder Unterstützung kontaktieren Sie das Entwicklungsteam unter: <a href="mailto:support@ethernaldoc.com">support@ethernaldoc.com</a>.
          </p>
        </section>
      </div>
      )}
      {language === 'es' && (
      <div className="content">
        <section>
          <h2>Introducción</h2>
          <p>
            Bienvenidos a Ethernal Doc, la aplicación descentralizada (dApp) diseñada para proporcionar una forma segura y transparente de cargar, verificar y registrar documentos en la blockchain. En un mundo donde la integridad de los datos es crucial, Ethernal Doc ofrece una solución confiable para garantizar la autenticidad de sus documentos digitales.
          </p>
          <p>
            Con Ethernal Doc, puede:
          </p>
          <ul>
            <li><strong>Cargar Documentos</strong>: Cargue sus archivos directamente desde la interfaz de usuario simple e intuitiva. Soportamos una amplia gama de formatos de archivo, incluidos PDFs, imágenes, audio y video.</li>
            <li><strong>Verificar Documentos</strong>: Verifique si sus documentos ya están registrados en la blockchain. Esto asegura que sus documentos no hayan sido manipulados.</li>
            <li><strong>Registrar Documentos</strong>: Si el documento aún no está en la blockchain, puede registrarlo fácilmente, asegurando que cualquier modificación futura pueda ser rastreada y verificada.</li>
            <li><strong>Buscar Documentos por Hash</strong>: Utilice el hash del documento para buscar rápidamente y verificar la presencia del documento en la blockchain.</li>
          </ul>
          <p>
            Ethernal Doc utiliza la tecnología blockchain para crear un libro mayor inmutable y transparente de transacciones. Cada documento registrado se asocia con un hash único, que actúa como una huella digital del archivo. Este hash se guarda en la blockchain, lo que hace imposible su alteración sin ser detectado.
          </p>
          <p>
            La aplicación se integra perfectamente con MetaMask, una de las soluciones más populares para la gestión de identidades y billeteras en Ethereum. Asegúrese de que MetaMask esté instalado y configurado correctamente para interactuar con Ethernal Doc.
          </p>
          <p>
            Comience a usar Ethernal Doc ahora para garantizar la seguridad e integridad de sus documentos digitales.
          </p>
        </section>
  
        <section>
          <h2>Requisitos</h2>
          <ul>
            <li>Un navegador web moderno (por ejemplo, Chrome, Firefox)</li>
            <li>MetaMask instalado como una extensión del navegador</li>
            <li>Ether (ETH) para pagar comisiones de transacción en la red Ethereum o L2 relacionada.</li>
          </ul>
        </section>
  
        <section>
          <h2>Características Principales</h2>
          <h3>Carga de Documentos</h3>
          <p>
            1. Arrastre y suelte un archivo en el área de carga o haga clic en el botón "Cargar archivo" para seleccionar un archivo de su computadora.
            2. Una vez cargado el archivo, se calculará el hash del documento y se mostrará una vista previa del documento.
          </p>
  
          <h3>Verificación de Documentos</h3>
          <p>
            1. Después de cargar un archivo, la aplicación verifica si el hash del documento ya existe en la blockchain.
            2. Si el documento existe, se mostrarán los detalles del documento (por ejemplo, hash, nombre, etiqueta, fecha de creación, etc.).
            3. Si el documento no existe, se mostrará un mensaje de error y el usuario puede elegir si desea registrar el documento en la blockchain.
          </p>
  
          <h3>Registro de Documentos en la Blockchain</h3>
          <p>
            1. Ingrese un nombre y una etiqueta (ambos opcionales) para el documento.
            2. Haga clic en el botón "Hacer Eterno" para iniciar la transacción que registra el documento en la blockchain.
            3. Confirme la transacción en MetaMask y espere a que se complete la transacción.
            4. Una vez completada, se mostrará un mensaje de éxito.
          </p>
  
          <h3>Búsqueda de Documentos por Hash</h3>
          <p>
            1. Ingrese el hash del documento en el campo de búsqueda ubicado en la sección de carga.
            2. Haga clic en el botón "Buscar Hash" para verificar la existencia del documento en la blockchain.
            3. Si el documento existe, se mostrarán los detalles del documento.
          </p>
        </section>
  
        <section>
          <h2>Problemas Comunes</h2>
          <ul>
            <li><strong>Error "Document does not exist"</strong>: Asegúrese de que el hash del documento sea correcto y que el documento haya sido previamente registrado en la blockchain.</li>
            <li><strong>Transacción fallida</strong>: Verifique que tenga fondos suficientes en ETH para cubrir las tarifas de transacción.</li>
            <li><strong>Problemas de conexión con MetaMask</strong>: Asegúrese de que MetaMask esté instalado y que su cuenta esté conectada a la red correcta (por ejemplo, Ethereum Mainnet).</li>
          </ul>
        </section>
  
        <section>
          <h2>Contacto</h2>
          <p>
            Para obtener más información o soporte, contacte al equipo de desarrollo en: <a href="mailto:support@ethernaldoc.com">support@ethernaldoc.com</a>.
          </p>
        </section>
      </div>
      )}
      {language === 'fr' && (
      <div className="content">
      <section>
        <h2>Introduction</h2>
        <p>
          Bienvenue sur Ethernal Doc, l'application décentralisée (dApp) conçue pour offrir un moyen sécurisé et transparent de télécharger, vérifier et enregistrer des documents sur la blockchain. Dans un monde où l'intégrité des données est cruciale, Ethernal Doc offre une solution fiable pour garantir l'authenticité de vos documents numériques.
        </p>
        <p>
          Avec Ethernal Doc, vous pouvez :
        </p>
        <ul>
          <li><strong>Télécharger des Documents</strong> : Téléchargez vos fichiers directement depuis l'interface utilisateur simple et intuitive. Nous supportons une large gamme de formats de fichiers, y compris les PDFs, images, audio et vidéo.</li>
          <li><strong>Vérifier des Documents</strong> : Vérifiez si vos documents sont déjà enregistrés sur la blockchain. Cela garantit que vos documents n'ont pas été falsifiés.</li>
          <li><strong>Enregistrer des Documents</strong> : Si le document n'est pas encore sur la blockchain, vous pouvez facilement l'enregistrer, assurant que toute modification future peut être suivie et vérifiée.</li>
          <li><strong>Rechercher des Documents par Hash</strong> : Utilisez le hash du document pour rechercher rapidement et vérifier la présence du document sur la blockchain.</li>
        </ul>
        <p>
          Ethernal Doc utilise la technologie blockchain pour créer un registre immuable et transparent des transactions. Chaque document enregistré est associé à un hash unique, agissant comme une empreinte digitale du fichier. Ce hash est ensuite enregistré sur la blockchain, rendant toute altération impossible sans être détectée.
        </p>
        <p>
          L'application s'intègre parfaitement avec MetaMask, l'une des solutions les plus populaires pour la gestion des identités et des portefeuilles sur Ethereum. Assurez-vous que MetaMask est installé et correctement configuré pour interagir avec Ethernal Doc.
        </p>
        <p>
          Commencez à utiliser Ethernal Doc dès maintenant pour garantir la sécurité et l'intégrité de vos documents numériques.
        </p>
      </section>

      <section>
        <h2>Exigences</h2>
        <ul>
          <li>Un navigateur web moderne (par exemple, Chrome, Firefox)</li>
          <li>MetaMask installé comme extension de navigateur</li>
          <li>Ether (ETH) pour payer les frais de transaction sur le réseau Ethereum ou L2 apparenté.</li>
        </ul>
      </section>

      <section>
        <h2>Fonctionnalités Principales</h2>
        <h3>Téléchargement de Documents</h3>
        <p>
          1. Faites glisser et déposez un fichier dans la zone de téléchargement ou cliquez sur le bouton "Télécharger un fichier" pour sélectionner un fichier depuis votre ordinateur.
          2. Une fois le fichier téléchargé, le hash du document sera calculé et un aperçu du document sera affiché.
        </p>

        <h3>Vérification de Documents</h3>
        <p>
          1. Après avoir téléchargé un fichier, l'application vérifie si le hash du document existe déjà sur la blockchain.
          2. Si le document existe, les détails du document (par exemple, hash, nom, tag, date de création, etc.) seront affichés.
          3. Si le document n'existe pas, un message d'erreur sera affiché et l'utilisateur pourra choisir d'enregistrer le document sur la blockchain.
        </p>

        <h3>Enregistrement de Documents sur la Blockchain</h3>
        <p>
          1. Entrez un nom et un tag (les deux facultatifs) pour le document.
          2. Cliquez sur le bouton "Rendre Éternel" pour initier la transaction qui enregistre le document sur la blockchain.
          3. Confirmez la transaction dans MetaMask et attendez que la transaction soit complétée.
          4. Une fois complétée, un message de succès sera affiché.
        </p>

        <h3>Recherche de Documents par Hash</h3>
        <p>
          1. Entrez le hash du document dans le champ de recherche situé dans la section de téléchargement.
          2. Cliquez sur le bouton "Rechercher un Hash" pour vérifier l'existence du document sur la blockchain.
          3. Si le document existe, les détails du document seront affichés.
        </p>
      </section>

      <section>
        <h2>Problèmes Courants</h2>
        <ul>
          <li><strong>Erreur "Document does not exist"</strong> : Assurez-vous que le hash du document est correct et que le document a été précédemment enregistré sur la blockchain.</li>
          <li><strong>Transaction échouée</strong> : Vérifiez que vous avez suffisamment de fonds en ETH pour couvrir les frais de transaction.</li>
          <li><strong>Problèmes de connexion avec MetaMask</strong> : Assurez-vous que MetaMask est installé et que votre compte est connecté au bon réseau (par exemple, Ethereum Mainnet).</li>
        </ul>
      </section>

      <section>
        <h2>Contact</h2>
        <p>
          Pour plus d'informations ou de support, contactez l'équipe de développement à : <a href="mailto:support@ethernaldoc.com">support@ethernaldoc.com</a>.
        </p>
      </section>
    </div>
      )}
    </div>
  );
};

export default Documentation;
