import React, { useState } from 'react';
import './RecentDocuments.css';
import TransactionModal from './TransactionModal';

const RecentDocuments = ({ documents }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const filteredDocuments = documents.filter((doc) => {
    const documentHash = doc.documentHash ? doc.documentHash.toLowerCase() : '';
    const name = doc.name ? doc.name.toLowerCase() : '';
    const tag = doc.tag ? doc.tag.toLowerCase() : '';
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return (
      documentHash.includes(lowerCaseSearchTerm) ||
      name.includes(lowerCaseSearchTerm) ||
      tag.includes(lowerCaseSearchTerm)
    );
  });

  const openModal = (transaction) => {
    setSelectedTransaction(transaction);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTransaction(null);
  };

  const shortenHash = (hash) => {
    if (!hash) return '';
    return `${hash.slice(0, 6)}...${hash.slice(-4)}`;
  };

  return (
    <div className="recent-documents">
      <div className="search-section">
        <input
          type="search"
          placeholder="Search by hash, name or tag"
          className="searchBar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="document-header">
        <span>Hash</span>
        <span>Name</span>
        <span>Created On</span>
        <span>Tag</span>
      </div>
      {filteredDocuments.map((doc, index) => (
        <div className="document-row" key={index} onClick={() => openModal(doc)}>
          <div className="document-hash">{shortenHash(doc.documentHash)}</div>
          <div className="document-name">{doc.name}</div>
          <div className="document-date">{ new Date(doc.createdOn).toLocaleDateString()}</div>
          <div className="document-tag">{doc.tag}</div>
        </div>
      ))}
      <TransactionModal
        isOpen={isModalOpen}
        onClose={closeModal}
        transaction={selectedTransaction}
      />
    </div>
  );
};

export default RecentDocuments;
