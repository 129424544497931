import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-links">
        <Link to="/app/documentation">Documentation</Link>
        <Link to="/app/api">API</Link>
        <Link to="/app/service-fees">Service Fees</Link>
        <Link to="/app/privacy-policy">Privacy Policy</Link>
      </div>
    </footer>
  );
};

export default Footer;