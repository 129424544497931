import React from 'react';
import './WelcomeSection.css';
import welcomeImage from './welcome.png';
import { useNavigate } from 'react-router-dom';

const WelcomeSection = () => {
  const navigate = useNavigate();

  const openDocumentation = () => {
    navigate('/app/documentation');
  };

  return (
    <div className="welcome-section">
      <div className="welcome-text">
        <h4>where your files become timeless...</h4>
      </div>
      <img src={welcomeImage} alt="Welcome" className="welcome-graphic" />
      <div className="welcome-actions">
        <button className="button" onClick={openDocumentation}>Open Documentation</button>
      </div>
    </div>
  );
};

export default WelcomeSection;
