import { useState, useEffect, useCallback } from 'react';
import { ethers } from 'ethers';

const NETWORKS = {
  base: {
    chainId: '0x14a34',
    chainName: 'Base Testnet (Sepolia)',
    rpcUrls: ['https://base-sepolia-rpc.publicnode.com'],
    blockExplorerUrls: ['https://base-sepolia.blockscout.com'],
    nativeCurrency: {
      name: 'Sepolia Ether',
      symbol: 'ETH',
      decimals: 18,
    },
  },
};

export const useWeb3Provider = () => {
  const [provider, setProvider] = useState(null);
  const [network, setNetwork] = useState('base');
  const [currentChainId, setCurrentChainId] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(provider);

      provider.getNetwork().then((network) => {
        setCurrentChainId(ethers.utils.hexValue(network.chainId));
      });

      window.ethereum.on('chainChanged', (chainId) => {
        setCurrentChainId(ethers.utils.hexValue(chainId));
        if (ethers.utils.hexValue(chainId) !== NETWORKS[network].chainId) {
          disconnect();
        }
      });
    } else {
      console.error('MetaMask is not installed');
    }
  }, [network]);

  const disconnect = useCallback(() => {
    setIsConnected(false);
    window.location.reload();
  }, []);

  const switchNetwork = async (networkName) => {
    if (!provider) {
      console.error('Provider not initialized');
      return;
    }

    const networkDetails = NETWORKS[networkName];
    if (!networkDetails) {
      console.error('Invalid network');
      return;
    }

    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: networkDetails.chainId }],
      });
      setNetwork(networkName);
      setIsConnected(true);
    } catch (error) {
      console.error('Failed to switch network', error);
      if (error.code === 4902) {
        // This error code indicates that the chain has not been added to MetaMask.
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: networkDetails.chainId,
                chainName: networkDetails.chainName,
                rpcUrls: networkDetails.rpcUrls,
                nativeCurrency: networkDetails.nativeCurrency,
                blockExplorerUrls: networkDetails.blockExplorerUrls,
              },
            ],
          });
          setNetwork(networkName);
          setIsConnected(true);
        } catch (addError) {
          console.error('Failed to add network to MetaMask', addError);
        }
      }
    }
  };

  return {
    provider,
    network,
    switchNetwork,
    networks: NETWORKS,
    isConnected,
    disconnect,
  };
};
