import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import logoImg from './logo.png';
import baseLogo from './base_logo.png';
import ShortenAddress from '../common/ShortenAddress';
import Button from '../common/Button';
import './Header.css';

const Header = ({ web3Provider }) => {
  const { userAddress, connectMetaMask } = useUser();
  const { switchNetwork, networks, network, currentChainId, isConnected } = web3Provider;
  const navigate = useNavigate();
  const [selectedNetwork, setSelectedNetwork] = useState(network);

  useEffect(() => {
    if (currentChainId && networks[network] && currentChainId !== networks[network].chainId) {
      alert(`The network selected on MetaMask (${currentChainId}) is different from the one selected (${networks[network].chainId}).`);
    }
  }, [currentChainId, network]);

  const handleNetworkChange = async (event) => {
    const networkName = event.target.value;
    setSelectedNetwork(networkName);
    try {
      await switchNetwork(networkName);
    } catch (error) {
      console.error('Network switch failed', error);
    }
  };

  const handleLogoClick = () => {
    navigate('/');
    window.location.reload();
  };

  const handleConnectClick = async () => {
    if (currentChainId !== networks[selectedNetwork].chainId) {
      try {
        await switchNetwork(selectedNetwork);
      } catch (error) {
        console.error('Failed to switch network before connecting', error);
        return;
      }
    }
    connectMetaMask();
  };

  return (
    <header className="header">
      <div className="logoSection" onClick={handleLogoClick}>
        <img src={logoImg} alt="Logo" className="logo" />
        <span className="appName" onClick={handleLogoClick}>Ethernal Doc</span>
      </div>
      <div className="network-section">
        <select value={selectedNetwork} onChange={handleNetworkChange} className="networkSelect">
          {Object.keys(networks).map((key) => (
            <option key={key} value={key}>{networks[key].chainName}</option>
          ))}
        </select>
        {userAddress && isConnected ? (
          <div className="userInfo">
            <img src={baseLogo} alt="User" className="userImage" />
            <span className="userName"><ShortenAddress address={userAddress} /></span>
          </div>
        ) : (
          <Button onClick={handleConnectClick} className="connectButton">
            Connect Wallet
          </Button>
        )}
      </div>
    </header>
  );
};

export default Header;
