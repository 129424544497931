import React from 'react';
import './TransactionModal.css';

const TransactionModal = ({ isOpen, onClose, transaction }) => {
  if (!isOpen) return null;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Transaction Details</h2>
        <div className="transaction-details">
          <p><strong>Hash:</strong> <span className="hash">{transaction?.documentHash}</span></p>
          <p><strong>Name:</strong> {transaction?.name}</p>
          <p><strong>Tag:</strong> {transaction?.tag}</p>
          <p><strong>Created On:</strong> {formatDate(transaction?.createdOn)}</p>
        </div>
        <button className="close-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );  
};

export default TransactionModal;
