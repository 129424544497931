// src/components/landing/LandingPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import logoImg from '../header/logo.png';  // Assicurati che il percorso dell'immagine sia corretto
import './LandingPage.css';

const LandingPage = () => {
  const navigate = useNavigate();

  const goToApp = () => {
    navigate('/app');
  };

  return (
    <div className="landing-page">
      <header className="landing-header">
        <img src={logoImg} alt="Logo" className="logo" />
        <button className="go-to-app-button" onClick={goToApp}>
          GO TO APP
        </button>
      </header>
      <main className="landing-content">
        <section className="hero-section">
          <div className="hero-text">
            <h1>Welcome to Ethernal Doc</h1>
            <p>The decentralized application (dApp) designed to provide a secure and transparent way to upload, verify, and register documents on the blockchain.</p>
            <button className="hero-button" onClick={goToApp}>Start Now</button>
          </div>
        </section>
        <section className="features-section">
          <h2>Main Features</h2>
          <div className="feature">
            <h3>Upload Documents</h3>
            <p>Upload your files directly from the simple and intuitive user interface. We support a wide range of file formats, including PDFs, images, audio, and video.</p>
          </div>
          <div className="feature">
            <h3>Verify Documents</h3>
            <p>Check if your documents are already registered on the blockchain. This ensures that your documents have not been tampered with.</p>
          </div>
          <div className="feature">
            <h3>Register Documents</h3>
            <p>If the document is not yet on the blockchain, you can easily register it, ensuring that any future modifications can be tracked and verified.</p>
          </div>
          <div className="feature">
            <h3>Search Documents by Hash</h3>
            <p>Use the document hash to quickly search and verify the presence of the document on the blockchain.</p>
          </div>
        </section>
        <section className="how-it-works-section">
          <h2>How It Works</h2>
          <div className="step">
            <h3>Uploading Documents</h3>
            <p>1. Drag and drop a file into the upload area or click the "Upload file" button to select a file from your computer. 2. Once the file is uploaded, the document's hash will be calculated, and a preview of the document will be displayed.</p>
          </div>
          <div className="step">
            <h3>Verifying Documents</h3>
            <p>1. After uploading a file, the application checks if the document's hash already exists on the blockchain. 2. If the document exists, the document details (e.g., hash, name, tag, creation date, etc.) will be displayed. 3. If the document does not exist, an error message will be shown, and the user can choose to register the document on the blockchain.</p>
          </div>
          <div className="step">
            <h3>Registering Documents on the Blockchain</h3>
            <p>1. Enter a name and tag (both optional) for the document. 2. Click the "Make Timeless" button to initiate the transaction that registers the document on the blockchain. 3. Confirm the transaction in MetaMask and wait for the transaction to complete. 4. Once completed, a success message will be displayed.</p>
          </div>
          <div className="step">
            <h3>Searching Documents by Hash</h3>
            <p>1. Enter the document hash in the search field located in the upload section. 2. Click the "Search Hash" button to verify the document's existence on the blockchain. 3. If the document exists, the document details will be displayed.</p>
          </div>
        </section>
        <section className="requirements-section">
          <h2>Requirements</h2>
          <ul>
            <li>A modern web browser (e.g., Chrome, Firefox)</li>
            <li>MetaMask installed as a browser extension</li>
            <li>Ether (ETH) to pay transaction fees on the Ethereum or relative L2 network</li>
          </ul>
        </section>
      </main>
      <footer className="landing-footer">
        <p>&copy; 2024 Ethernal Doc. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default LandingPage;
