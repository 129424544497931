import React from 'react';
import './Api.css';

const Api = () => {
  return (
    <div className="api">
      <h1>API Documentation</h1>
      <div className="construction-message">
        <p>Our API documentation is currently under construction.</p>
        <p>We are working hard to bring you a comprehensive guide to our API endpoints, methods, and usage examples.</p>
        <p>Stay tuned for updates! The full API documentation will be released soon.</p>
        <p>For any immediate questions or support, please contact our development team at: <a href="mailto:support@ethernaldoc.com">support@ethernaldoc.com</a>.</p>
      </div>
    </div>
  );
};

export default Api;
