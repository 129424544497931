import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header/Header';
import Dashboard from './components/dashboard/Dashboard';
import Footer from './components/footer/Footer';
import Documentation from './components/documentation/Documentation';
import Api from './components/api/Api';
import ServiceFees from './components/serviceFees/ServiceFees';
import PrivacyPolicy from './components/privacyPolicy/PrivacyPolicy';
import LandingPage from './components/landing/LandingPage';
import { UserProvider } from './components/UserContext';
import { useWeb3Provider } from './hooks/useWeb3Provider';
import './App.css';

function App() {
  const web3Provider = useWeb3Provider();

  return (
    <UserProvider>
      <Router>
        <div className="app">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/app/*" element={
              <>
                <Header web3Provider={web3Provider} />
                <main className="main-content">
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/documentation" element={<Documentation />} />
                    <Route path="/api" element={<Api />} />
                    <Route path="/service-fees" element={<ServiceFees />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  </Routes>
                </main>
                <Footer />
              </>
            } />
          </Routes>
        </div>
      </Router>
    </UserProvider>
  );
}

export default App;
