import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useWeb3Provider } from '../hooks/useWeb3Provider';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [userAddress, setUserAddress] = useState(null);
  const { disconnect } = useWeb3Provider();

  const connectMetaMask = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setUserAddress(accounts[0]);
      } catch (error) {
        console.error('Failed to connect MetaMask', error);
      }
    } else {
      console.error('MetaMask is not installed');
    }
  };

  const handleAccountsChanged = useCallback((accounts) => {
    if (accounts.length > 0) {
      setUserAddress(accounts[0]);
    } else {
      setUserAddress(null);
      disconnect();
    }
  }, [disconnect]);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
      window.ethereum.on('disconnect', disconnect);

      return () => {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
        window.ethereum.removeListener('disconnect', disconnect);
      };
    } else {
      console.error('MetaMask is not installed');
    }
  }, [handleAccountsChanged, disconnect]);

  return (
    <UserContext.Provider value={{ userAddress, connectMetaMask }}>
      {children}
    </UserContext.Provider>
  );
};
